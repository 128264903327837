
import { FASTIFY_API } from '@/config/local';
import { logtoClient } from '@/LogtoContainer';
import { tryFreshToken } from '@/utils/try';
import { message } from 'antd';
import type { woodService } from 'blog/type-file'
import dayjs from 'dayjs';
import { InferRequestType, InferResponseType, hc } from 'hono/client'
import { isNil, isUndefined, omitBy } from 'lodash-es';
type AppType = typeof woodService;
const app = 'wood';
export const honoClient = hc<AppType>(`${FASTIFY_API}/wood`, {
    async fetch(url, options) {
        try {
            const idTokenClaims = await logtoClient.getIdTokenClaims();

            if (isNil(idTokenClaims)) {
                // 没登陆 尝试获取token
                console.log('没登陆 尝试获取token');
                await tryFreshToken(app);
            }

            // 如果过期了 刷新token
            if (
                idTokenClaims &&
                dayjs().isSameOrAfter(dayjs((idTokenClaims?.exp - 60 * 5) * 1000))
            ) {
                await tryFreshToken(app);
            }
        } catch (err) {
            console.log(err);
            location.href = `/account/sign-in?app=${app}`;
            throw new Error('unauthorized');
        }

        // 拿已经有的token
        const idToken = await tryFreshToken(app);

        options.headers.set("Authorization", idToken && `Bearer ${idToken}`)

        return fetch(url, options).then(async res => {
            if (res.status !== 200) {
                const body = await res.json()

                if (body.error.name === 'ZodError') {
                    throw new Error(body.error.issues.at(0).message);
                }

                throw new Error(body.message);
            }

            return res;
        });
    }
})

